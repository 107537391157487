.rowC {
  display: inline-block;
  flex-direction: row;
  margin-left: 1rem;
  font-weight: normal;
}

.divC {
  justify-content: space-around;
  display: flex;
}
